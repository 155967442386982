    
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerReducer } from 'react-router-redux';
import AppReducer from './reducers/app-reducer';
import AlertReducer from './reducers/alert-messages';

// Create store for redux and import reducers
export default function configureStore(preloadedState) {
    let store = null;
    // If dev enable Redux Devtool extension
    if (module.hot) {
        let actionCreators = {};
        store = createStore(combineReducers({
                AppReducer,
                AlertReducer,
                router: routerReducer   
            }), compose(applyMiddleware(thunk),
            window.devToolsExtension ? window.devToolsExtension({actionCreators}) : f => f
        ));
    }
    else {
        store = createStore(
            combineReducers({
                AppReducer,
                AlertReducer,
                router: routerReducer   
            }), applyMiddleware(thunk));
        }
    return store
}
  