import { addDataToCache } from "../common/utils";

const defaultState = {
    labels: [],
    labelsCount: 0,
    labelAsset: [],
    labelAssetCount: 0,
    asset: null,
    labelName: "",
    user: {},
    isAuth: false,
    metaData: null,
    darkTheme: true,
    searchSuggestions: [],
    labelAssets: [],
    searchResults: {},
    labelData: {},
    seriesData: {},
    seasonData: [],
    orientation: "",
	packagesList : [],
    pkgSubscriptionRes : {},
    paymentInfo : {},
    userInfo: {},
    loginFromPackage: false,
    favorite: [],
    favoriteAsset: [],
    recentAsset: [],
    faqList: [],
    langList: [],
    labelId: "",
    pageUrl: "",
    episodes: [],
    activeSeason: "",
    episodesCount: 0
};

const AppReducer = (state = defaultState, action) => {
    switch (action.type) {
        // Set current user based on user token in local storage when login/reloads
        case 'SET_CURRENT_USER':
            return Object.assign({}, state, {
                user: action.user,
                isAuth: Object.keys(action.user).length > 0 ? true : false
            });

        // Get Labels for Home page
        case 'GET_LABELS':
            const ids =  state.labels.concat(action.labels).map(o => o.sequence_id);
            let label_data = state.labels.concat(action.labels).filter(({sequence_id}, index) => !ids.includes(sequence_id, index + 1));
            if(action.pageUrl) {
                // if(window && window.location.pathname == "/") {
                // localStorage.setItem(`label_data_${action.pageUrl}`, JSON.stringify(label_data))
                // }
                localStorage.setItem(`label_count_${action.pageUrl}`, action.labelsCount);
                addDataToCache(action.pageUrl, label_data);
            }
            return Object.assign({}, state, {
                labels: label_data,
                labelsCount: action.labelsCount,
                pageUrl: action.pageUrl
            });

        // Get Labels for Home page
        case 'SET_LABELS':
            // const ids_1 =  state.labels.concat(action.labels).map(o => o.sequence_id);
            const ids_1 = action.labels.map(o => o.sequence_id);
            let label_data_1 = action.labels.filter(({sequence_id}, index) => !ids_1.includes(sequence_id, index + 1));            
            // let label_data_1 = state.labels.concat(action.labels).filter(({sequence_id}, index) => !ids_1.includes(sequence_id, index + 1));            
            return Object.assign({}, state, {
                labels: label_data_1,
                labelsCount: action.labelsCount,
                pageUrl: action.pageUrl
            });

        // Get Label Assets by iD
        case 'GET_LABELS_BY_ID':
            return Object.assign({}, state, {
                labelAsset: state.labelAsset.filter(c => action.labelAsset.some(d => d.akid === c.akid)).length > 0 ? state.labelAsset : state.labelAsset.concat(action.labelAsset),
                labelAssetCount: action.count,
                labelName: action.labelName,
                orientation: action.orientation,
                labelId: action.labelId
            });
        
        // Set current asset
        case 'GET_ASSET_BY_ID':
            return Object.assign({}, state, {
                asset: action.asset
            });
        
        // Set Series Data
        case 'GET_SERIES_DATA':
            return Object.assign({}, state, {
                seriesData: action.seriesData
            });
        
        // Set Season Data
        case 'GET_SEASON_DATA': 
            return Object.assign({}, state, {
                seasonData: action.seasonData
            });
        
        // Set Meta Data
        case 'SET_META_DATA':
            return Object.assign({}, state, {
                metaData: action.metaData
            });

        // Set Theme
        case 'SET_THEME':
            return Object.assign({}, state, {
                darkTheme: action.theme
            });

        // Set Search Suggestions
        case 'GET_SEARCH_SUGGESTIONS':
            return Object.assign({}, state, {
                searchSuggestions: action.searchSuggestions
            });

        // Set Search Results
        case 'GET_SEARCH_RESULTS':
            return Object.assign({}, state, {
                searchResults: action.searchResults
            });

        // Set Label Assets
        case 'GET_ASSETS_BY_LABEL_ID':
            const assetIds =  state.labelAssets.concat(action.labelAssets).map(o => o.akid);
            let label_asset_data = state.labelAssets.concat(action.labelAssets).filter(({akid}, index) => !assetIds.includes(akid, index + 1)).sort((a, b) => a - b.sequence_id);
            return Object.assign({}, state, {
                labelAssets: label_asset_data
            });

        // Set Label Data by Label ID
        case 'GET_LABEL_DATA_BY_LABEL_ID':
            return Object.assign({}, state, {
                labelData: action.labelData,
                labelAssetCount: action.labelData.totalResults
            });

        // Clear all datas 
        case 'RESET_HOME_DATA':
            return Object.assign({}, state, {
                labels: [],
                labelsCount: 0,
                labelAsset: [],
                labelAssetCount: 0,
                labelName: ""
            });

        // Reset to Empty data
        case 'RESET_LABEL_DATA':
            return Object.assign({}, state, {
                labelAsset: [],
                labelAssets: [],
                labelData: {},
                labelAssetCount: 0,
                labelName: ""
            });

        // Reset to Empty data
        case 'RESET_EPISODE_DATA':
            return Object.assign({}, state, {
                episodes: [],
                episodesCount: 0
            });
        
        // Set Package List
        case 'GET_PACKAGES_DATA':
            return Object.assign({}, state, {
                packagesList: action.packagesList
            });

        // Set Suer Data
        case 'GET_USER_DATA':
            return Object.assign({}, state, {
                userInfo: action.userInfo
            });

        // Set Package Subscription Response
        case 'PACKAGE_SUBSCRIPTION_DATA':
            return Object.assign({}, state, {
                pkgSubscriptionRes: action.pkgSubscriptionRes.data
            });

        case 'LOGIN_FROM_PKG':
            state.loginFromPackage = true;
            return {
                ...state,
                loginFromPackage: true,
            };

        case 'PAYMENT_INFO' :
            return Object.assign({}, state, {
                paymentInfo: action.paymentInfo
            });

        // Set Favorite
        case 'SET_FAVOURITE': 
            return Object.assign({}, state, {
                favorite: action.favorite
            });

        // Set Favorite
        case 'SET_FAV_RECENT': 
            return Object.assign({}, state, {
                favoriteAsset: action.favorite_asset,
                recentAsset: action.recent_asset
            });

        // Get Faq List
        case 'GET_FAQ_LIST': 
            return Object.assign({}, state, {
                faqList: action.faqList
            });

        // Get Lang List
        case 'GET_LANG_LIST': 
            return Object.assign({}, state, {
                langList: action.langList
            });

        // Get Lang List
        case 'GET_EPISODES': 
            // let episodeAssets = state.labels.concat(action.labels).map(o => o.sequence_id);
            const episodeIds = action.activeSeason == state.activeSeason ? state.episodes.concat(action.episodes)?.map(o => o.asset_id) : action.episodes.map(o => o.asset_id);
            return Object.assign({}, state, {
                episodes: action.activeSeason == state.activeSeason ? state.episodes.concat(action.episodes).filter(({asset_id}, index) => !episodeIds.includes(asset_id, index + 1)) : action.episodes,
                activeSeason: action.activeSeason,
                episodesCount: action.count
            });

        default:
            return state;
    }
};

export default AppReducer;