// add alert messgae actio for redux with type and payload
export function addAlertMessage(message) {
    return {
        type: 'ADD_ALERT_MESSAGE',
        message
    }
}

// delete alert messgae actio for redux with type and payload
export function deleteAlertMessage(id) {
    return {
        type: 'DELETE_ALERT_MESSAGE',
        id
    }
}