import { nanoid } from 'nanoid';
import findIndex from 'lodash/findIndex'; 

// Popup message reducer for adding and delete alert messages
const AlertReducer = (state = [], action) => {
    switch (action.type) {
        case 'ADD_ALERT_MESSAGE':
        return [
        ...state,
            {  
                    id: nanoid(),
                type: action.message.type,
                text: action.message.text
            }
        ]
        case 'DELETE_ALERT_MESSAGE':
            const index = findIndex(state, { id: action.id });
            if (index >=  0){
                return [
                    ...state.slice(0, index),
                    ...state.slice(index + 1)
                ];
            }
        
        default: return state;
    }
}

export default AlertReducer;
