import API from "./api";
import jwtDecode from 'jwt-decode';
// import history from "../history";
import { addAlertMessage } from "./alert-messages";
import { browserName, browserVersion } from "react-device-detect";
import { removeAllLocalCache, removeLocalStorage } from "../common/utils";
import axios from "axios";
import CONFIG, { ASSET_TYPES } from "../config";
import { INVALID_TOKEN } from "../common/constant";

const isServer = typeof window === "undefined";

// Signup API for Guest User
export function guestSignup(data) {
    return (dispatch) => {
        return API.post('/customer/guest-signup', data).then(
            async (res) => {
                if(res.data.success) {
                    if(!isServer) {
                        await localStorage.setItem("token", res.data.parameters.token);
                        await localStorage.setItem("customer_id", res.data.parameters.customer_id);
                    }
                }
            }
        )
    }
}

// Userinfo API for getting User details
export function getUserInfo(){
    return async (dispatch) => {
        let data = {
            auth_token: await localStorage.getItem("token"),
            customer_id: await localStorage.getItem("customer_id")
        };
        let config = {
            headers: {
                pid: process.env.P_ID
            }
        }
        return API.post('/customer/get-customer-data', data, config).then(
            (res) => {
                dispatch({ type: "GET_USER_DATA", userInfo : res.data.parameters })
                return res;
            }
        )
    }
}

// Get Saltkey and set token and customer_id for Guest users
export function getSaltKey(data) {
    return (dispatch) => {
        return API.post('/master/get-salt', data, { headers: {p_id: data.p_id }}).then(
            async (res) => {
                if(res && res.data.success) {
                    removeLocalStorage();
                    const user_data = res.data.parameters.user_data;
                    const auth_data = res.data.parameters.auth_data;
                    if(!isServer) {
                        await localStorage.setItem("token", user_data.token);
                        await localStorage.setItem("customer_id", user_data.customer_id);
                        await localStorage.setItem("session_id", auth_data.session_id);
                        await localStorage.setItem("access_token", auth_data.access_token);
                        await localStorage.setItem("refresh_token", auth_data.refresh_token);
                        await localStorage.setItem("expired_at", auth_data.expired_at);
                        await localStorage.setItem("client_secret", auth_data.client_secret);
                        await localStorage.setItem("country_code", auth_data.country_code);
                    }
                }
                return res
            }
        )
    }
}


// Get Refresh token API
export function getRefreshToken(data) {
    return dispatch => {
        return API.post('/master/refresh-access-token', data).then(
            async (res) => {
                if(res.data.success) {
                    let refresh_required = res.data.parameters.refresh_required;
                    let access_token = res.data.parameters.access_token;
                    let expired_at = res.data.parameters.expired_at;
                    localStorage.setItem("access_token", access_token);
                    localStorage.setItem("expired_at", expired_at);
                    if(refresh_required) {
                        await removeAllLocalCache();
                        window.location.reload();
                    }
                } else {
                    if(res.data.message === INVALID_TOKEN) {
                        dispatch(logout());
                    }
                }
            }
        ).catch(err => console.log(err))
    }
}

// Get Saltkey and set token and customer_id for Guest users for SSR(Server Side Rendering)
export function getSaltKeyStatic(data, page) {
    return {
        token: process.env.F_ENV_STATIC_TOKEN,
        access_token: process.env.F_ENV_STATIC_ACCESS_TOKEN,
        cid: process.env.F_ENV_STATIC_CUSTOMER_ID
    }
    // console.log("Calling salt ket API from server")
    // let d = data;
    // d.n_s = process.env.P_ID+process.env.HOST;
    // if(!process.env.F_ENV_TOKEN_STATIC) {

    // }
    // return new Promise((resolve, reject) => {
    //     API.post('/master/get-salt-static', data).then(
    //         (res) => {
    //             if(res.data.success) {
    //                 console.log("Sucess salt key data")
    //                 // console.log("Sucess salt key data", res.data)
    //                 const user_data = res.data.parameters.user_data;
    //                 const access_token = res.data.parameters.auth_data.access_token;
    //                 console.log(user_data.token, access_token, user_data.customer_id, "Salt");
    //                 resolve({
    //                     token: user_data.token,
    //                     access_token,
    //                     cid: user_data.customer_id,
    //                 })
    //             } else {
    //                 reject(res.data)
    //             }
    //         }
    //     )
    //     .catch(err => {
    //         console.log(err, "Salt key err");
    //         reject(err)
    //     })
    // })
}

// Set labels dispatch for storing in Redux
export function setLabels(data) {
    return dispatch => {
        if(data.success) {
            let labels = data.parameters.items;
            let count = data.parameters.totalResults;
            dispatch({ type: "SET_LABELS", labels, labelsCount: count, pageUrl: data.pageUrl });
            // dispatch({ type: "SET_META_DATA", metaData: data.parameters.page_meta_data })
        }
    }
}

// Fetch labels API for Home page with pagination and setting the data in redux
export function getLabels(data) {
    let config = {
        headers: {
            pid: data.pid,
            pageid: data.pageid,
            authority: data.authority
        }
    }
    return (dispatch) => {
        // return API.get(`/assets/get-page-json?page=${data.page}&pagesize=${data.limit}`, config).then(
        return API.get(`/assets/fetch-labels?page=${data.page}&pagesize=${data.limit}`, config).then(
            (res) => {
                if(res.data.success) {
                    // let ads = res.data.parameters.page_ads;
                    // let labels = res.data.parameters.items.map((c, i) => {
                    //     c.ads = i%2 == 0 ? ads[i%2] : null;
                    //     return c; 
                    // });
                    let count = res.data.parameters.totalResults;
                    // let labels = res.data.parameters[0].data.items;
                    // let count = res.data.parameters[0].data.totalResults;
                    dispatch({ type: "GET_LABELS", labels: res.data.parameters.items, labelsCount: count, pageUrl: data.pageUrl });
                    dispatch({ type: "SET_META_DATA", metaData: res.data.parameters.page_meta_data })
                    // dispatch({ type: "SET_META_DATA", metaData: res.data.parameters[0].data.page_meta_data })
                }
                return res;
            }
        ).catch(err => console.log(err, "Err"))
    }
}

// Fetch Favorite Recent API for Favorite and my list
export function getFavRecent(data) {
    let config = {
        headers: {
            pid: data.pid,
            pageid: data.pageid,
            authority: data.authority
        }
    }
    return (dispatch) => {
        return API.get(`/customer/get-fav-recent-watch`, config).then(
        // return API.get(`/assets/fetch-labels?page=${data.page}&pagesize=${data.limit}`, config).then(
            (res) => {
                if(res.data.success) {
                    let favorite_asset = res.data.parameters.favorite_asset;
                    let recent_asset = res.data.parameters.recent_asset;
                    dispatch({
                        type: "SET_FAV_RECENT",
                        favorite_asset: favorite_asset,
                        recent_asset: recent_asset
                    })
                }
                return res;
            }
        ).catch(err => console.log(err, "Err"))
    }
}

// Get Meta data for Homepage SSR(Server Side Rendering)
export function getPageMetaData(data, headers) {
    return new Promise((resolve, reject) => {
        let config = {
            headers: {
                pid: data.pid,
                pageid: data.pageid,
                ...headers
            }
        }
        API.get(`/assets/get-page-meta-data?page=${data.page}&pagesize=${data.limit}`, config).then(
            (res) => {
                if(res.data.success) {
                    console.log("Success Page meta data")
                    resolve(res);
                } else {
                    reject(res)
                }
            }
        ).catch(err => {
            console.log(err, "Server Err Success Page meta data")
            reject(err)
        })
    })
}

// Get Meta data for Homepage SSR(Server Side Rendering)
export function getPageData(data) {
    return new Promise((resolve, reject) => {
        let config = {
            headers: {
                pid: data.pid,
                pageid: data.pageid,
            }
        }
        API.get(`/assets/get-page-json?page=${data.page}&pagesize=${data.limit}`, config).then(
            (res) => {
                console.log("Success Page meta data")
                resolve(res);
            }
        ).catch(err => {
            console.log(err, "Server Err")
            reject(err)
        })
    })
}

// Get Banner data for Homepage SSR(Server Side Rendering)
export function getBannerData(data, header) {
    console.log("Calling Banner data")
    return new Promise((resolve, reject) => {
        let config = {
            headers: {
                pid: data.pid,
                pageid: data.pageid,
                ...header
            }
        }
        API.get(`/assets/get-banner-labels`, config).then(
            (res) => {
                console.log("Success Banner data")
                resolve(res);
            }
        ).catch(err => {
            console.log(err, "Server Err")
            reject(err)
        })
    })
}

// Get Banner data for Homepage SSR(Server Side Rendering)
export function updatePageDataJSON(data, header) {
    return new Promise((resolve, reject) => {
        let config = {
            headers: {
                pid: data.pid,
                pageid: data.pageid,
                ...header
            }
        }
        API.get(`/assets/update-page-json`, config).then(
            (res) => {
                console.log("Page JSON Data updated")
                resolve(res);
            }
        ).catch(err => {
            console.log(err, "Server Err")
            reject(err)
        })
    })
}

//Fetch lables for SSR(Server Side Rendering) to show as Static data
export function getLabelsMeta(data) {
    return new Promise((resolve, reject) => {
        let config = {
            headers: {
                pid: data.pid,
                authority: data.authority
            }
        }
        API.get(`/assets/fetch-labels?page=${data.page}&pagesize=${data.limit}`, config).then(
            (res) => {
                resolve(res);
            }
        ).catch(err => reject(err))
    })
}

// Fetch assets for Each Label API to show in the Label page
export function getLabelsbyId(data) {
    let config = {
        headers: {
            pid: data.pid,
            pageid: data.pageid,
            authority: data.authority,
            ostypeid: process.env.OS_TYPE_ID,
            version: browserVersion,
            model: browserName
        }
    }
    return (dispatch) => {
        return API.get(`/assets/fetch-assets?akid=${data.akid}&page=${data.page}&pagesize=${data.limit}`, config).then(
            (res) => {
                if(res.data.success) {
                    let labelAsset = res.data.parameters.asset_data.asset_type == ASSET_TYPES.SERIES ? res.data.parameters.items[0].asset : res.data.parameters.items;
                    let count = res.data.parameters.totalResults;
                    let labelName = res.data.parameters.label_name;
                    let labelId = res.data.parameters.label_id;
                    let asset = res.data.parameters.asset_data;
                    let orientation =  typeof res.data.parameters.orientation == "object" ? "HORIZONTAL" : res.data.parameters.orientation ? res.data.parameters.orientation : "HORIZONTAL";
                    dispatch({ type: "GET_ASSET_BY_ID", asset })
                    dispatch({ type: "GET_LABELS_BY_ID", labelAsset, count, labelName, orientation, labelId })
                }
                return res;
            }
        ).catch(err => console.log(err, "Err"))
    }
}

// Fetch assets for Each Label API to show in the Label page
export function getLabelsbyIdSeries(data) {
    let config = {
        headers: {
            pid: data.pid,
            pageid: data.pageid,
            authority: data.authority,
            ostypeid: process.env.OS_TYPE_ID,
            version: browserVersion,
            model: browserName
        }
    }
    return (dispatch) => {
        return API.get(`/assets/fetch-assets?akid=${data.akid}&page=${data.page}&pagesize=${data.limit}`, config).then(
            (res) => {
                if(res.data.success) {
                    let labelAsset = res.data.parameters.items;
                    let count = res.data.parameters.totalResults;
                    let labelName = res.data.parameters.label_name;
                    let labelId = res.data.parameters.label_id;
                    let asset = res.data.parameters.asset_data;
                    let orientation =  typeof res.data.parameters.orientation == "object" ? "HORIZONTAL" : res.data.parameters.orientation ? res.data.parameters.orientation : "HORIZONTAL";
                    dispatch({ type: "GET_ASSET_BY_ID", asset })
                    dispatch({ type: "GET_LABELS_BY_ID", labelAsset, count, labelName, orientation, labelId })
                }
                return res;
            }
        ).catch(err => console.log(err, "Err"))
    }
}

// Fetch assets for Each Label API to show in the Label page - SSR(Server Side Rendering)
export function getLabelsbyIdStatic(data, headers) {
    let config = {
        headers: {
            pid: data.pid,
            authority: data.authority,
            ...headers
        }
    }
    return new Promise((resolve, reject) => {
        API.get(`/assets/fetch-assets-static?akid=${data.akid}&page=${data.page}&pagesize=${data.limit}`, config).then(
            (res) => {
                if(res.data.success) {
                    let labelAssetStatic = res.data.parameters.asset_data.asset_type == ASSET_TYPES.SERIES ? res.data.parameters.items[0].asset : res.data.parameters.items;
                    let count = res.data.parameters.totalResults;
                    let labelName = res.data.parameters.label_name;
                    let labelId = res.data.parameters.label_id;
                    let asset = res.data.parameters.asset_data;
                    let orientation =  typeof res.data.parameters.orientation == "object" ? "HORIZONTAL" : res.data.parameters.orientation ? res.data.parameters.orientation : "HORIZONTAL";
                    let resData = { asset, labelAssetStatic, count, labelName, orientation, labelId };
                    resolve(resData)
                } else {
                    console.log(res.data, `Failed in /assets/fetch-assets-static?akid=${data.akid}&page=${data.page}&pagesize=${data.limit}`)
                    reject(res.data)
                }
            }
        ).catch(err => {
            console.log(err, "ERrr")
            reject(err);
        })
    });
}

// Fetch Meta data for Each AKID API - SSR(Server Side Rendering)
export function getLabelsbyIdMeta(data, headers) {
    return new Promise((resolve, reject) => {
        let config = {
            headers: {
                pid: data.pid,
                akid: data.aid,
                ...headers
            }
        }
        API.get(`/assets/get-asset-meta-data-akid`, config).then(
            (res) => {
                if(res.data.success) {
                    resolve(res);
                } else {
                    console.log(res.data, "Failed in /assets/get-asset-meta-data-akid", config.headers)
                    resolve(res.data)
                }
            }
        ).catch(err => reject(err))
    })
}

// Get Asset by Id api using in Player
export function getAssetById(data) {
    let config = {
        headers: {
            pid: data.pid,
            authority: data.authority
        }
    }
    return (dispatch) => {
        return API.post(`/assets/view-asset`, data, config).then(
            (res) => {
                if(res.data.success) {
                    let asset = res.data.parameters;
                    dispatch({ type: "GET_ASSET_BY_ID", asset })
                }
            }
        ).catch(err => console.log(err, "Err"))
    }
}
    
// get label meta data's - SSR(Server Side Rendering)
export function getLabelsByLabelIdMeta(data, headers) {
    return new Promise((resolve, reject) => {
        let config = {
            headers: {
                pid: data.pid,
                authority: data.authority,
                lid: data.label_id,
                ...headers
            }
        }
        API.get(`/assets/get-label-meta-data`, config).then(
            (res) => {
                resolve(res);
            }
        ).catch(err => reject(err))
    })
}

//  Fetch assets using Label Id 
export function getLabelsByLabelId(data) {
    return (dispatch) => {
        let config = {
            headers: {
                pid: data.pid,
                authority: data.authority,
                pageid: data.pageid
            }
        }
        return API.get(`/assets/fetch-assets-lid?lid=${data.label_id}&page=${data.page}&pagesize=${data.limit}`, config).then(
            (res) => {
                if(res.data.success) {
                    dispatch({ type: "GET_ASSETS_BY_LABEL_ID", labelAssets: res.data.parameters.items })
                    dispatch({ type: "GET_LABEL_DATA_BY_LABEL_ID", labelData: {
                        label_name: res.data.parameters.label_name,
                        totalResults: res.data.parameters.totalResults,
                        orientation: typeof res.data.parameters.orientation == "object" ? "HORIZONTAL" : res.data.parameters.orientation ? res.data.parameters.orientation : "HORIZONTAL"
                    }})
                }
                return res;
            }
        ).catch(err => console.log(err))
    }
}

//  Fetch assets using Label Id - SSR(Server Side Rendering)
export function getLabelsByLabelIdStatic(data, headers) {
    return new Promise((resolve, reject) => {
        let config = {
            headers: {
                pid: data.pid,
                authority: data.authority,
                ...headers,
                pageid: data.pageid
            }
        }
        API.get(`/assets/fetch-assets-lid-static?lid=${data.label_id}&page=${data.page}&pagesize=${data.limit}`, config).then(
            (res) => {
                if(res.data.success) {
                    console.log(res.data, "Res")
                    let d = {
                        labelAssets: res.data.parameters.items,
                        labelData: {
                            label_name: res.data.parameters.label_name,
                            totalResults: res.data.parameters.totalResults,
                            orientation: typeof res.data.parameters.orientation == "object" ? "HORIZONTAL" : res.data.parameters.orientation ? res.data.parameters.orientation : "HORIZONTAL",
                            ...res.data.parameters.labelData
                        }
                    }
                    resolve(d)
                } else {
                    reject(res)
                }
            }
        ).catch(err => reject(err))
    })
}

//  Fetch footer labels - SSR(Server Side Rendering)
export function getFooterLabelsStatic(data, headers) {
    return new Promise((resolve, reject) => {
        let config = {
            headers: {
                pid: data.pid,
                authority: data.authority,
                pageid: data.pageid,
                ...headers
            }
        }
        API.get(`/assets/get-footer-labels?pagesize=${data.pagesize}`, config).then(
            (res) => {
                if(res.data.success) {
                    console.log("Success Footer lables")
                    resolve(res.data.parameters.items)
                } else {
                    reject(res.data)
                }
            }
        ).catch(err => {
            console.log(err, "Footer label err")
            reject(err);
        })
    })
}

// Set current user on login
export function setCurrentUser(user) {
    return {
        type: "SET_CURRENT_USER",
        user
    }
}

// Login or register API
export function loginOrRegister(data, isLogin) {
    return (dispatch) => {
        return API.post('/customer/register', data).then(
            async (res) => {
                if(res.data.success) {
                    if(isLogin) {
                        removeLocalStorage();
                        let token = res.data.parameters.token;
                        let jsonWebToken = token.substring(7);
                        await localStorage.setItem("token", token);
                        await localStorage.setItem("customer_id", res.data.parameters.userData.customer_id);
                        await localStorage.setItem("session_id", res.data.parameters.auth_data.session_id);
                        await localStorage.setItem("country_code", res.data.parameters.auth_data.country_code);
                        let access_token = res.data.parameters.auth_data.access_token;
                        let refresh_token = res.data.parameters.auth_data.refresh_token;
                        let expired_at = res.data.parameters.auth_data.expired_at;
                        let client_secret = res.data.parameters.auth_data.client_secret;
                        await localStorage.setItem("access_token", access_token);
                        await localStorage.setItem("refresh_token", refresh_token);
                        await localStorage.setItem("expired_at", expired_at);
                        await localStorage.setItem("client_secret", client_secret);
                        dispatch(setCurrentUser(jwtDecode(jsonWebToken)));
                    }
                    // Adding popup message from server response
                    // dispatch(addAlertMessage({ type: "success", text: res.data.message }))
                } else {
                    // Adding popup message from server response if error occurs
                    if(res.data.message !== INVALID_TOKEN) {
                        dispatch(addAlertMessage({ type: "error", text: res.data.message }))
                    }
                }
                return res
            }
        ).catch(err => console.log(err, "Err"))
    }
}

// Login or register API
export function login(data) {
    return (dispatch) => {
        return API.post('/customer/login', data).then(
            async (res) => {
                if(res.data.success) {
                    removeLocalStorage();
                    let token = res.data.parameters.token;
                    let jsonWebToken = token.substring(7);
                    await localStorage.setItem("token", token);
                    await localStorage.setItem("customer_id", res.data.parameters.userData.customer_id);
                    await localStorage.setItem("session_id", res.data.parameters.auth_data.session_id);
                    await localStorage.setItem("country_code", res.data.parameters.auth_data.country_code);
                    let access_token = res.data.parameters.auth_data.access_token;
                    let refresh_token = res.data.parameters.auth_data.refresh_token;
                    let expired_at = res.data.parameters.auth_data.expired_at;
                    let client_secret = res.data.parameters.auth_data.client_secret;
                    await localStorage.setItem("access_token", access_token);
                    await localStorage.setItem("refresh_token", refresh_token);
                    await localStorage.setItem("expired_at", expired_at);
                    await localStorage.setItem("client_secret", client_secret);
                    dispatch(setCurrentUser(jwtDecode(jsonWebToken)));
                    // Adding popup message from server response
                    // dispatch(addAlertMessage({ type: "success", text: res.data.message }))
                } else {
                    // Adding popup message from server response if error occurs
                    if(res.data.message !== INVALID_TOKEN) {
                        dispatch(addAlertMessage({ type: "error", text: res.data.message }))
                    }
                }
                return res
            }
        ).catch(err => console.log(err, "Err"))
    }
}

// Get Otp for Login
export function sendOtp(data) {
    return (dispatch) => {
        return API.post('/customer/send-otp', data).then(
            async (res) => {
                if(res.data.success) {
                    dispatch(addAlertMessage({ type: "success", text: res.data.message }))
                } else {
                    // Adding popup message from server response if error occurs
                    if(res.data.message !== INVALID_TOKEN) {
                        dispatch(addAlertMessage({ type: "error", text: res.data.message }))
                    }
                }
                return res
            }
        ).catch(err => console.log(err, "Err"))
    }
}

// Get Otp for Login
export function resendOtp(data) {
    return (dispatch) => {
        return API.post('/customer/resend-otp', data).then(
            async (res) => {
                if(res.data.success) {
                    dispatch(addAlertMessage({ type: "success", text: res.data.message }))
                } else {
                    // Adding popup message from server response if error occurs
                    if(res.data.message !== INVALID_TOKEN) {
                        dispatch(addAlertMessage({ type: "error", text: res.data.message }))
                    }
                }
                return res
            }
        ).catch(err => console.log(err, "Err"))
    }
}

// Get Otp for Login
export function verifyOtp(data) {
    return (dispatch) => {
        return API.post('/customer/verify-otp', data).then(
            async (res) => {
                if(res.data.success) {
                    let token = res.data.parameters.token;
                    let jsonWebToken = token.substring(7);
                    await localStorage.setItem("token", token);
                    await localStorage.setItem("customer_id", res.data.parameters.userData.customer_id);
                    await localStorage.setItem("session_id", res.data.parameters.auth_data.session_id);
                    await localStorage.setItem("country_code", res.data.parameters.auth_data.country_code);
                    let access_token = res.data.parameters.auth_data.access_token;
                    let refresh_token = res.data.parameters.auth_data.refresh_token;
                    let expired_at = res.data.parameters.auth_data.expired_at;
                    let client_secret = res.data.parameters.auth_data.client_secret;
                    await localStorage.setItem("access_token", access_token);
                    await localStorage.setItem("refresh_token", refresh_token);
                    await localStorage.setItem("expired_at", expired_at);
                    await localStorage.setItem("client_secret", client_secret);
                    dispatch(setCurrentUser(jwtDecode(jsonWebToken)));
                    // Adding popup message from server response
                    dispatch(addAlertMessage({ type: "success", text: res.data.message }))
                } else {
                    // Adding popup message from server response if error occurs
                    if(res.data.message !== INVALID_TOKEN) {
                        dispatch(addAlertMessage({ type: "error", text: res.data.message }))
                    }
                }
                return res
            }
        ).catch(err => console.log(err, "Err"))
    }
}

// Social Login API - Facebook, Google
export function socialLogin(data) {
    return (dispatch) => {
        return API.post('/customer/social-login', data).then(
            async (res) => {
                if(res.data.success) {
                    removeLocalStorage();
                    let token = res.data.parameters.token;
                    let jsonWebToken = token.substring(7);
                    await localStorage.setItem("token", token);
                    await localStorage.setItem("customer_id", res.data.parameters.userData.customer_id);
                    await localStorage.setItem("session_id", res.data.parameters.auth_data.session_id);
                    await localStorage.setItem("country_code", res.data.parameters.auth_data.country_code);
                    let access_token = res.data.parameters.auth_data.access_token;
                    let refresh_token = res.data.parameters.auth_data.refresh_token;
                    let expired_at = res.data.parameters.auth_data.expired_at;
                    let client_secret = res.data.parameters.auth_data.client_secret;
                    await localStorage.setItem("access_token", access_token);
                    await localStorage.setItem("refresh_token", refresh_token);
                    await localStorage.setItem("expired_at", expired_at);
                    await localStorage.setItem("client_secret", client_secret);
                    dispatch(setCurrentUser(jwtDecode(jsonWebToken)));
                    // Adding popup message from server response
                    // dispatch(addAlertMessage({ type: "success", text: res.data.message }))
                } else {
                    if(res.data.message !== INVALID_TOKEN) {
                        dispatch(addAlertMessage({ type: "error", text: res.data.message }))
                    }
                }
                return res
            }
        ).catch(err => console.log(err, "Err"))
    }
}

// Forgot Password API
export function forgotPassword(data) {
    return (dispatch) => {
        return API.post('/customer/forgot-password', data).then(
            async (res) => {
                if(res.data.success) {
                    dispatch(addAlertMessage({ type: "success", text: res.data.message }))
                    return res;
                    // history.push('/');
                } else {
                    dispatch(addAlertMessage({ type: "info", text: res.data.message }))
                    return res;
                }
            }
        ).catch(err => console.log(err, "Err"))
    }
}

// Logout action - Remove all local stored data
export function logout() {
    return async (dispatch) => {
        removeLocalStorage();
        dispatch(setCurrentUser({}));
        window.location.pathname = '/';
    }
}

// Validate reset password token by link
export function validatePasswordToken(data) {
    return (dispatch) => {
        return API.post('/customer/validate-fptoken', data).then(
            (res) => {
                return res
            }
        )
    }
}
// If token valid set new password API
export function changePassword(data) {
    return (dispatch) => {
        return API.post('/customer/change-password', data).then(
            (res) => {
                // if(res.data.success) {
                //     dispatch(addAlertMessage({ type: "success", text: res.data.message }))
                // } else {
                //     if(res.data.message !== INVALID_TOKEN) {
                        // dispatch(addAlertMessage({ type: "error", text: res.data.message }))
                    // }
                // }
                return res
            }
        )
    }
}

export function updatePassword(data) {
    return (dispatch) => {
        return API.post('/customer/update-password', data).then(
            (res) => {
                if(res.data.success) {
                    dispatch(addAlertMessage({ type: "success", text: res.data.message }))
                } else {
                    if(res.data.message !== INVALID_TOKEN) {
                        dispatch(addAlertMessage({ type: "error", text: res.data.message }))
                    }
                }
            }
        )
    }
}

export function switchMode(theme) {
    return {
        type: "SET_THEME",
        theme
    }
}

export function getSuggestions(data) {
    let config = {
        headers: {
            pid: data.pid,
            authority: data.authority
        }
    }
    return (dispatch) => {
        return API.get(`/assets/search-suggestion?q=${data.search_string}`, config).then(
            (res) => {
                if(res.data.success && res.data.parameters.status) {
                    dispatch({ type: "GET_SEARCH_SUGGESTIONS", searchSuggestions: res.data.parameters.result })
                }
            }
        )
    }
}

export function getSearchResults(data) {
    let config = {
        headers: {
            pid: data.pid,
            authority: data.authority
        }
    }
    return (dispatch) => {
        return API.get(`/assets/search?q=${data.search_string}`, config).then(
            (res) => {
                if(res.data.success) {
                    let searchRes = {
                        all: {
                            status: true,
                            result: Object.entries(res.data.parameters).filter(([k, v]) => v.status == true).map(([key, val]) => val.result).flat()
                        },
                        ...res.data.parameters
                    };
                    dispatch({ type: "GET_SEARCH_RESULTS", searchResults: searchRes })
                } 
                return res
            }
        )
    }
}

export function getCountryCode() {
    return new Promise((resolve) => {
        axios.get("https://geolocation-db.com/json/").then((res) => {
            if(res && res.data && res.data.country_code) {
                resolve(res.data.country_code);
            }
        }).catch(err => {
            resolve("US");
        })
    })
}

export function saveUserData(data) {
    let config = {
        headers: {
            pid: process.env.P_ID
        }
    }
    return (dispatch) => {
        return API.post('/customer/update-customer-data', data, config).then(
            async (res) => {         
                if(res.data.success) {
                    // if(res.data.success) {
                    //     if(!isServer) {
                    //         let token = res.data.parameters.token;
                    //         let jsonWebToken = token.substring(7);
                    //         await localStorage.setItem("token", token);
                    //         await localStorage.setItem("customer_id", res.data.parameters.userData.customer_id);
                    //         dispatch(setCurrentUser(jwtDecode(jsonWebToken)))
                    //     }
                    // }
                    dispatch(addAlertMessage({ type: "success", text: res.data.message }))
                    dispatch(getUserInfo());
                } else {
                    if(res.data.message !== INVALID_TOKEN) {
                        dispatch(addAlertMessage({ type: "error", text: res.data.message }))
                    }
                }
                return res;
            }
        )
    }
}

export function fetchSeasonDataStatic(data, headers) {
    let config = {
        headers: {
            pid: process.env.P_ID,
            cid: data.cid,
            ...headers
        }
    }
    return new Promise((resolve, reject) => {
        API.get(`/assets/fetch-episodes-static?aid=${data.aid}`, config).then(
            (res) => {
                if(res.data.success) {
                    let d = {
                        seriesData: res.data.parameters.seriesData,
                        seasonData: res.data.parameters.seasonsData,
                        asset: res.data.parameters.activeAssetData != null ? res.data.parameters.activeAssetData[0] : null
                    }
                    resolve(d);
                } else {
                    reject(res)
                }
            }
        ).catch(err => reject(err))
    }) 
}

export function fetchSeasonAssetDataStatic(data, headers) {
    let config = {
        headers: {
            pid: process.env.P_ID,
            cid: data.cid,
            ...headers
        }
    }
    return new Promise((resolve, reject) => {
        API.post(`/assets/view-asset`, data, config).then(
            (res) => {
                if(res.data.success) {
                    resolve(res.data.parameters);
                } else {
                    reject(res.data)
                }
            }
        ).catch(err => reject(err))
    }) 
}

export function fetchSeasonData(data, episode) {
    let config = {
        headers: {
            pid: process.env.P_ID,
            authvalue: data.authvalue,
            cid: data.cid
        }
    }
    return (dispatch) => {
        return API.get(`/assets/fetch-episodes?aid=${data.aid}`, config).then(
            (res) => {
                if(res.data.success) {
                    dispatch({ type: "GET_SERIES_DATA", seriesData: res.data.parameters.seriesData })
                    dispatch({ type: "GET_SEASON_DATA", seasonData: res.data.parameters.seasonsData })
                    let asset = episode == null ? res.data.parameters.activeAssetData[0] : episode;
                    if(asset.banner_w == null) {
                        asset["banner_w"] = res.data.parameters.activeAssetData[0].banner_w
                        asset["banner_m"] = res.data.parameters.activeAssetData[0].banner_m
                    }
                    dispatch({ type: "GET_ASSET_BY_ID", asset })
                }
                return res;
            }
        )
    }
}

export function fetchSeriesData(data) {
    let config = {
        headers: {
            pid: process.env.P_ID,
        }
    }
    return (dispatch) => {
        return API.post(`/assets/view-asset`, data, config).then(
            (res) => {
                if(res.data.success) {
                    let asset = res.data.parameters;
                    if(asset.banner_w == null) {
                        asset["banner_w"] = res.data.parameters.banner_w
                        asset["banner_m"] = res.data.parameters.banner_m
                    }
                    dispatch({ type: "GET_ASSET_BY_ID", asset })
                }
                return res;
            }
        )
    }
}

export function fetchEpisodes(data) {
    let config = {
        headers: {
            pid: process.env.P_ID,
            // authvalue: data.authvalue,
        }
    }
    return (dispatch) => {
        return API.get(`/assets/get-episodes-by-season?aid=${data.aid}&sno=${data.sno}&page=${data.page}&pagesize=${data.limit}`, config).then(
            (res) => {
                if(res.data.success) {
                    dispatch({ type: "GET_EPISODES", episodes: res.data.parameters.items, activeSeason: data.sno, count: res.data.parameters.totalResults })
                }
            }
        )
    }
}


export function getPackageList() {
    let config = {
        headers: {
            p_id: process.env.P_ID,
            authority: process.env.AUTHORITY,
        }
    }
    return (dispatch) => {
        return API.get(`/package/lists`, config).then(
            async (res) => {             
                if (res.data.success && res.data.parameters.items) {
                    const packageMap = new Map();
                    res.data.parameters.items.map((item) => {
                        if (packageMap.get(item.bundle) !== null && packageMap.get(item.bundle) !== undefined) {
                            const packageItem = packageMap.get(item.bundle);
                            packageItem[packageItem.length] = item;
                            packageMap.set(item.bundle, packageItem);
                        }
                        else {
                            const packageItem = [];
                            packageItem[0] = item;
                            packageMap.set(item.bundle, packageItem);
                        }
                    });

                    const packageList = [];
                    packageMap.forEach((value, name) => packageList.push({ name, value }));
                    dispatch({ type: "GET_PACKAGES_DATA", packagesList: packageList })
                }
                return res;
            }
        )
    }
}



/*export function packageSubscription(data) {

    return (dispatch) => {
        return  API.post(`/customer/package-subscription`, data).then(
            async (res) => {
              
               // if(res.data.success) {
                    dispatch({ type: "PACKAGE_SUBSCRIPTION_DATA", pkgSubscriptionRes : res})
              //  }
            }
        )
    }
}*/

export function packageSubscriptionBulk(data) {
    let config = {
        headers: {
            pid: process.env.P_ID,
        }
    }
    return (dispatch) => {
        return  API.post(`/customer/package-subscription-bulk`, data,config).then(
            async (res) => {
                dispatch({ type: "PACKAGE_SUBSCRIPTION_DATA", pkgSubscriptionRes : res})
                if(!res.data.success) {
                    window.location.pathname = "/account";        
                }
            }
        )
    }
}

export function loginFromPackageSub() {
    return (dispatch) => { dispatch({ type: "LOGIN_FROM_PKG", loginFromPackage : true})}     
}


export function saveEventsData(data) {
    return (dispatch) => {
        return API.post('/customer/save-viewing-data', data, { headers: { p_id: process.env.P_ID}}).then(
            async (res) => {
                // let refresh_required = res.data.refresh_required;
                if(res.data.success) {
                    if(res.data && res.data.parameters && res.data.parameters.event_session_id) {
                        localStorage.setItem("event_session_id", res.data.parameters.event_session_id)
                    }
                    // dispatch(addAlertMessage({ type: "success", text: res.data.message }))
                } else {
                    if(res.data.message === INVALID_TOKEN) {
                        dispatch(logout())
                    }
                }
                // if(refresh_required) {
                //     dispatch(logout())
                // }
            }
        )
    }
}


export function intiatePayment(data) {
    let config = {
        headers: {
            pid: process.env.P_ID,
        }
    }
    return (dispatch) => {
        return API.post('/package/initiate-payment', data,config).then(
           async (res) => {
                if(res.data.success) {
                    dispatch({ type: "PAYMENT_INFO", paymentInfo: res.data.parameters})
                }
                else {
                    dispatch({ type: "PAYMENT_INFO", paymentInfo: {'paymentFailed': true}})
                }
            }
        )
    }
}


// make Favouite API call
export function makeFavourite(data) {
    return (dispatch) => {
        return API.post(`/customer/fav-recent-watch`, data, { headers: { pid: process.env.P_ID }}).then(
            (res) => {
                if(res.data.success) {
                    dispatch(getFavRecent({  pid: process.env.P_ID, authority: process.env.AUTHORITY}))
                    // let favorite = res.data.parameters.favorite_asset;
                    // localStorage.setItem("favorite", JSON.stringify(favorite))
                    // dispatch({ type: "SET_FAVOURITE", favorite: favorite })
                }
            }
        ).catch(err => console.log(err))
    }
}

// make Favouite API call
export function sendFeedback(data, pid) {
    return (dispatch) => {
        return API.post(`/customer/asset-feedback`, data, { headers: { pid: pid }}).then(
            (res) => {
                if(res.data.success) {
                    dispatch(addAlertMessage({ type: "success", text: res.data.message }));
                }
            }
        ).catch(err => console.log(err))
    }
}

// Contact Form API call
export function sendContactForm(data, headers) {
    return (dispatch) => {
        return API.post(`/customer/contact-us`, data, { headers: { pid: headers.pid }}).then(
            (res) => {
                if(res.data.success) {
                    dispatch(addAlertMessage({ type: "success", text: res.data.message }));
                } else {
                    if(res.data.message !== INVALID_TOKEN) {
                        dispatch(addAlertMessage({ type: "error", text: res.data.message }))
                    }
                }
                return res;
            }
        ).catch(err => console.log(err))
    }
}

// Contact Form API call
export function getFaqList(headers) {
    return (dispatch) => {
        return API.post(`/master/get-faq-details`, {}, {headers: {pid: headers.pid}}).then(
            (res) => {
                if(res && res.data && res.data.success) {
                    dispatch({ type: 'GET_FAQ_LIST', faqList: res.data.parameters})
                }
                return res;
            }
        ).catch(err => console.log(err))
    }
}

// Contact Form API call
export function getLangList(headers) {
    return (dispatch) => {
        return API.post(`/master/get-languages-list`, {}, {headers: {pid: headers.pid}}).then(
            (res) => {
                if(res && res.data && res.data.success) {
                    dispatch({ type: 'GET_LANG_LIST', langList: res.data.parameters})
                }
                return res;
            }
        ).catch(err => console.log(err))
    }
}

// Add Error log
export function addError(data, headers) {
    console.log("Calling add error API", data.status_code)
    return (dispatch) => {
        return API.post('/fe-error-log', data, { headers: headers }).then(
            (res) => {
                console.log(res, "Add err log res")
                return res;
            }
        ).catch(err => console.log(err, "Err add err log")) 
    }
}


// Get Saltkey and set token and customer_id for Guest users for SSR(Server Side Rendering)
export function getPagesStatic(data, headers) {
    console.log("Calling pages API from server")
    return new Promise((resolve, reject) => {
        API.get('/page/listall', { headers: headers }).then(
            async (res) => {
                if(res.data.success) {
                    console.log("Sucess pages data")
                    resolve(res.data.parameters)
                } else {
                    console.log("Sucess pages data error 222")
                    reject(res.data)
                }
            }
        )
        .catch(err => {
            console.log(err, "Pages err");
            reject(err)
        })
    })
}

// Get Saltkey and set token and customer_id for Guest users for SSR(Server Side Rendering)
export function getPagesStaticApp(data, headers) {
    console.log("Calling pages API from server")
    return new Promise((resolve, reject) => {
        axios.get(`${CONFIG.apiHost}/page/listall`, { headers: headers }).then(
            async (res) => {
                if(res.data.success) {
                    console.log("Sucess pages data")
                    resolve(res.data.parameters)
                } else {
                    console.log("Sucess pages data error 111")
                    reject(res.data)
                }
            }
        )
        .catch(err => {
            console.log(err, "Pages err");
            reject(err)
        })
    })
}

export function resetHomeData() {
    return (dispatch) => {
        return new Promise((resolve) => {
            dispatch({ type: "RESET_HOME_DATA"});
            resolve(true);
        })
    }
}