import axios from 'axios';
import CONFIG from '../config';
import { removeLocalStorage } from '../common/utils';

// If code is rendered throw node, check if window is available to use local storage
const ISSERVER = typeof window === "undefined";

// Common Axios instance for calling all API's
const API = axios.create({
    headers: {
        common: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*'
        }
    },
    // Common base url
    baseURL: CONFIG.apiHost
});

if(!ISSERVER) {
    API.interceptors.request.use(
        async (config) => {
            // Inssert Auth for Each API request here Globally using axios interceptor
            const token = await localStorage.getItem('token');
            const userid = await localStorage.getItem('customer_id');
            const access_token = await localStorage.getItem('access_token');
            const session_id = await localStorage.getItem('session_id');
            config.headers["Authorization"] = token === null ? "" : token;
            config.headers["cid"] = userid === null ? "" : userid;
            config.headers["oauthtoken"] = access_token === null ? "" : access_token;
            config.headers["logid"] = session_id === null ? "" : session_id;
            if(window.location.href.includes("localhost")) {
                config.headers["sitename"] = CONFIG.SITE_URL;
            }
            return config
        }
    )
}
if(!ISSERVER) {
    API.interceptors.response.use(
        response => response,
        async (err) => {
            const { status } = err.response;
            console.log(status, "Err")
            if(status == 401) {
                removeLocalStorage();
                window.location.pathname = "/";
                return true;
            }
            return err
        }
    )
}

export default API;