import jwtDecode from "jwt-decode";
import { IMGIX_URL, LOGO_URL, S3_URL, S3_URL_1, S3_URL_YT } from "../config";
import moment from "moment";
import { store } from "../../pages/_app";
import { logout } from "../actions/actions";
import { countries } from "./constant";

export async function removeAllLabels() {
    return new Promise(async (resolve) => {
        let keys = Object.keys(localStorage);
        let res = await Promise.all(
            keys.map(key => {
                return new Promise(async (resolve) => {
                    if(key.includes("label_")) {
                        localStorage.removeItem(key);
                        resolve(true);
                    } else {
                        resolve(true);
                    }
                })  
            })
        );
        resolve(res);
    })
}

export function removeLocalStorage() {
    localStorage.removeItem("token");
    localStorage.removeItem("customer_id");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("salt_key");
    localStorage.removeItem("country_code");
    localStorage.removeItem("expired_at");
    localStorage.removeItem("selectedPackage");
    removeAllLabels();
    deleteAllDataFromCache();
}

export function removeAllLocalCache() {
    return new Promise(async (resolve) => {
        let token = localStorage.getItem("token");
        if(token) {
            let jsonWebToken = token.substring(7);
            const decodedToken = jwtDecode(jsonWebToken);
            if(decodedToken.type === "G") {
                // store.dispatch(logout());
                removeLocalStorage();
            } else {
                localStorage.setItem("expired_at", moment().subtract(1, "hr").format())
            }
        }
        await removeAllLabels();
        await deleteAllDataFromCache();
        resolve(true);
    })
}

export function deleteAllDataFromCache() {
    return new Promise((resolve) => {
        if("caches" in window) {
            caches.keys().then(async (keys) => {
                let res = await Promise.all(keys.map((key) => caches.delete(key)))
                resolve(res);
            })
        }
    })
}

export function addDataToCache(pageUrl, labelData) {
    const data = new Response(JSON.stringify(labelData));
    if("caches" in window) {
        caches.open(`label_data_${pageUrl}`).then(cache => {
            cache.put(`label_data_${pageUrl}`, data);
        })
    }
}

export function getDataFromCache(pageUrl) {
    return new Promise((resolve, reject) => {
        if("caches" in window) {
            caches.open(`label_data_${pageUrl}`).then(async cache => {
                const cacheResponse = await cache.match(`label_data_${pageUrl}`);
                const data = await cacheResponse.json();
                resolve(data);
            }).catch(err => resolve(false))
        } else {
            resolve(false)
        }
    })
}

export const getImigxUrl = (url) => {
    if(typeof url === "string") {
        if(url.includes(S3_URL_YT)) {
            return url;
            // return url.replace(S3_URL_YT, IMGIX_URL);
        } else if(url.includes(S3_URL_1)) {
            return url.replace(S3_URL_1, IMGIX_URL);
        } else if(url.includes(S3_URL)) {
            return url.replace(S3_URL, IMGIX_URL);
        } else {
            return url;
        }
    } else {
        return "";
    }
}


export const getCountryCodeFromDialCode = (dial_code) => {
    if(dial_code && countries.length > 0) {
        return countries.filter((each) => each.dial_code === dial_code)[0].code;
    } else {
        return "US";
    }
}

export const getCountryCodeFromDialCode1 = (dial_code) => {
    if(typeof window !== "undefined") {
        let country_code = localStorage.getItem("country_code");
        if(dial_code && countries.length > 0 && country_code) {
            let country =  countries.filter((each) => each.dial_code === dial_code && country_code === each.code);
            return country.length > 0 ? country[0].code : "US";
        } else {
            return "US";
        }
    }
    return "US";
}

export const getDialCodeFromCountryCode = (country_code) => {
    if(typeof window !== "undefined") {
        let countryCode = localStorage.getItem("country_code");
        if(countryCode && countries.length > 0) {
            return countries.filter((each) => each.code === countryCode)[0].dial_code;
        } else {
            return countries.filter((each) => each.code === "US")[0].dial_code;
        }
    }
    return countries.filter((each) => each.code === "US")[0].dial_code;
}

export const getTimeFormat = (duration) => {
    console.log(duration)
    // Hours, minutes and seconds
    const hrs = ~~(duration / 3600);
    const mins = ~~((duration % 3600) / 60);
    const secs = ~~duration % 60;
  
    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = "";
  
    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
  
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
  
    return ret;
}

var initialTimestamp, bufferChart, bitrateChart;
var updateCount = 0;

function clearChart() {
    bufferChart.destroy();
    bitrateChart.destroy();
}

function addNewData(videoBuffer, audioBuffer, bitrate) {
    var currentTimeDiff = (Date.now() - initialTimestamp) / 1000;
    addChartData(bufferChart, 0, currentTimeDiff, videoBuffer);
    addChartData(bufferChart, 1, currentTimeDiff, audioBuffer);
    addChartData(bitrateChart, 0, currentTimeDiff, bitrate / 1000000);
}

export function updateCharts(videoBuffer, audioBuffer, bitrate) {
    addNewData(videoBuffer, audioBuffer, bitrate);
}

function addChartData(chart, seriesIndex, xAxis, yAxis) {
    chart.series[seriesIndex].addPoint([xAxis, yAxis], true, false);
}

export function setupChart() {
    initialTimestamp = Date.now();

    window.Highcharts.theme = {
        colors: ["#90EE7E", "#F45B5B", "#7798BF", "#FF9655", "#AAEEEE", "#DF5353", "#7798BF", "#55BF3B", "#DDDF0D", "#FFDD77"],
        chart: {
            backgroundColor: "#202020",
            style: {
                color: "#ccc",
                fontWeight: 400,
                fontFamily: "Ember-Light"
            }
        },
        title: {
            style: {
                color: "#ccc",
                fontWeight: 400,
                fontFamily: "Ember-Light"
            }
        },
        xAxis: {
            labels: {
                style: {
                    color: "#ccc",
                    fontWeight: 400,
                    fontFamily: "Ember-Light"
                }
            }
        },
        yAxis: {
            labels: {
                style: {
                    color: "#ccc",
                    fontWeight: 400,
                    fontFamily: "Ember-Light"
                }
            }
        }
    };
    
    window.Highcharts.setOptions(Highcharts.theme);

    bufferChart = window.Highcharts.chart(document.getElementById("buffer-chart"), {
        chart: {
            type: 'spline',
            zoomType: 'x',
            spacing: [20, 10, 10, 10] // top right bottom left
        },
        credits: {
            enabled: false
        },
        title: {
            text: 'Buffer Levels'
        },
        xAxis: {
            title: {
                text: 'time',
                align: 'low'
            },
            min: 0
        },
        yAxis: {
            title: {
                text: 'sec',
                align: 'high'
            },
            min: 0
        },
        legend: {
            align: 'center',
            verticalAlign: 'bottom'
        },
        series: [{
            name: 'Video',
            data: [
                [0, 0]
            ],
            marker: {
                enabled: true,
                fillColor: '#ffffff',
                lineWidth: 2,
                lineColor: null,
                symbol: 'circle'
            },
            color: '#1FAAE2'
        }, {
            name: 'Audio',
            data: [
                [0, 0]
            ],
            marker: {
                enabled: true,
                fillColor: '#ffffff',
                lineWidth: 2,
                lineColor: null,
                symbol: 'circle'
            },
            color: '#F49D1D'
        }],

        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        }
    });


    bitrateChart = window.Highcharts.chart(document.getElementById("bitrate-chart"), {
        chart: {
            type: 'spline',
            zoomType: 'x',
            spacing: [20, 10, 10, 10] // top right bottom left
        },
        credits: {
            enabled: false
        },
        title: {
            text: 'Bitrate'
        },
        xAxis: {
            title: {
                text: 'time',
                align: 'low'
            },
            min: 0
        },
        yAxis: {
            title: {
                text: 'Mbps',
                align: 'high'
            },
            min: 0
        },
        legend: {
            align: 'center',
            verticalAlign: 'bottom'
        },
        series: [{
            name: 'Video',
            data: [
                [0, 0]
            ],
            marker: {
                enabled: true,
                fillColor: '#ffffff',
                lineWidth: 2,
                lineColor: null,
                symbol: 'circle'
            },
            color: '#1FAAE2'
        }],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        }
    });
};
  
export const getNumberOfMenus = (width, totalPages) => {
    if(width > 1900) {
        return totalPages > 8 ? 8 : totalPages;
    } else if(width > 1600) {
        return totalPages > 7 ? 7 : totalPages 
    } else if(width > 1400) {
        return totalPages > 7 ? 7 : totalPages 
    } else if(width > 1199) {
        return totalPages > 5 ? 5 : totalPages
    } else if(width > 1099) {
        return totalPages > 5 ? 5 : totalPages
    } else if(width > 1000) {
        return totalPages > 4 ? 4 : totalPages
    } else if(width > 768) {
        return 4
    } else if(width > 587) {
        return 3
    }
    return 1
}

export const getVericalImgUrl = (asset) => {
    if(asset.url_v) {
        return asset.url_v;
    }
    if(asset.banner_w) {
        return asset.url_v;
    }
    if(asset.banner_h) {
        return asset.url_h;
    }
    return LOGO_URL;
}

export const getImgUrlByOrientation = (asset, orientation) => {
    if(["HORIZONTAL", "HORIZONTAL_LG", "TRENDING", "FEATURED", "BANNER", ""].includes(orientation)) {
        return asset.url_h || asset.banner_w || asset.url_s || asset.url_v   
    }
    if(["VERTICAL", "VERTICAL_LG"].includes(orientation)) {
        return asset.url_v || asset.banner_w || asset.url_s || asset.url_h   
    }
    if(["SQUARE", "CIRCLE"].includes(orientation)) {
        return asset.url_s || asset.banner_w || asset.url_h || asset.url_s   
    }
}