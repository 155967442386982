
// Globla config For All Pages
const CONFIG = {
    apiHost: process.env.API_HOST,
    ad: true,
    adSenseid: process.env.AD_SENSE_ID,
    LOGO_URL_50: process.env.LOGO_URL_50,
    APP_NAME: process.env.APP_NAME,
    HELP_MAIL: process.env.HELP_MAIL,
    INFO_MAIL: process.env.INFO_MAIL,
    AD_UNITS: {
        // HOMEPAGE: {
        //     3 : "2132536250",
        //     5 : "2783288851",
        //     7 : "8151149698",
        //     9 : "5886280134",
        //     11 : "3881582250",
        //     13 : "2132536250",
        //     15 : "2783288851",
        //     17 : "8151149698",
        //     19 : "5886280134",
        //     21 : "3881582250",
        //     23 : "2132536250",
        //     25 : "2783288851",
        // },
        // HOMEPAGE_MOBILE: {
        //     2 : "5018756950",
        //     4 : "4888014579",
        //     6 : "8495005526",
        //     8 : "8243686090",
        //     10 : "9173624382",
        //     12 : "5018756950",
        //     14 : "4888014579",
        //     16 : "8495005526",
        //     18 : "8243686090",
        //     20 : "9173624382",
        //     22 : "5018756950",
        //     24 : "4888014579",
        // },
        FOOTER: process.env.FOOTER_AD,
        // PLAYER_SQUARE_1: "4573198469",
        // PLAYER_SQUARE_2: "5464318394",
        // PLAYER_SQUARE_3: "3406837559",
        PLAYER_RECTANGLE: process.env.PLAYER_RECTANGLE_AD
    },
    OTP_RESEND_TIMER: 180,
    LOGO_URL: process.env.LOGO_URL,
    APP_URL: process.env.APP_URL,
    SITE_URL: process.env.SITE_URL,
    BLOG_URL: process.env.BLOG_URL,
    FACEBOOK_URL: process.env.FACEBOOK_URL,
    TWITTER_URL: process.env.TWITTER_URL,
    APP_URL_SHARE: process.env.APP_URL_SHARE,
    BRADMAX_CLIENT_TOKEN: process.env.BRADMAX_CLIENT_TOKEN,
    S3_URL: process.env.S3_URL,
    S3_URL_1: process.env.S3_URL_1,
    S3_URL_YT: process.env.S3_URL_YT,
    IMGIX_URL: process.env.IMGIX_URL,
    ANDROID_LINK: process.env.ANDROID_LINK,
    IOS_LINK: process.env.IOS_LINK,
    DRM_LICENSE: process.env.DRM_LICENSE,
    PAGES: {
        HOMEPAGE: {
            initialPage: 1,
            pageLimit: 10,
            HORIZONTAL: [
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 4500,
                    settings: {
                        slidesToShow: 6.5,
                        slidesToScroll: 6,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 3500,
                    settings: {
                        slidesToShow: 6.5,
                        slidesToScroll: 6,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 2500,
                    settings: {
                        slidesToShow: 6.5,
                        slidesToScroll: 7,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 2000,
                    settings: {
                        slidesToShow: 6.1,
                        slidesToScroll: 6,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 1800,
                    settings: {
                        slidesToShow: 5.1,
                        slidesToScroll: 5,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 1600,
                    settings: {
                        slidesToShow: 5.1,
                        slidesToScroll: 5,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 4.1,
                        slidesToScroll: 4.1,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4.1,
                        slidesToScroll: 4.1,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3.6,
                        slidesToScroll: 3.6,
                        initialSlide: 1,
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1.8,
                        slidesToScroll: 1,
                        initialSlide: 1,
                    }
                }
            ],
            SQUARE: [
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 4500,
                    settings: {
                        slidesToShow: 10.5,
                        slidesToScroll: 10,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 3500,
                    settings: {
                        slidesToShow: 9.5,
                        slidesToScroll: 9,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 2500,
                    settings: {
                        slidesToShow: 8.5,
                        slidesToScroll: 8,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 2000,
                    settings: {
                        slidesToShow: 7,
                        slidesToScroll: 7,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 1600,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 6,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 5.1,
                        slidesToScroll: 5,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4.1,
                        slidesToScroll: 4,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3.1,
                        slidesToScroll: 3,
                        initialSlide: 1,
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2.5,
                        slidesToScroll: 2.5,
                        initialSlide: 1
                    }
                }
            ],
            CIRCLE: [
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 4500,
                    settings: {
                        slidesToShow: 10.5,
                        slidesToScroll: 10,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 3500,
                    settings: {
                        slidesToShow: 9.5,
                        slidesToScroll: 9,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 2500,
                    settings: {
                        slidesToShow: 8.5,
                        slidesToScroll: 8,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 2000,
                    settings: {
                        slidesToShow: 7.1,
                        slidesToScroll: 7,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 1600,
                    settings: {
                        slidesToShow: 6.1,
                        slidesToScroll: 6,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 5.1,
                        slidesToScroll: 5,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4.1,
                        slidesToScroll: 4,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4.1,
                        slidesToScroll: 4.1,
                        initialSlide: 1,
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2.5,
                        slidesToScroll: 2.5,
                        initialSlide: 1
                    }
                }
            ],
            VERTICAL: [
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 4500,
                    settings: {
                        slidesToShow: 7.5,
                        slidesToScroll: 7,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 3500,
                    settings: {
                        slidesToShow: 7.5,
                        slidesToScroll: 7,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 2500,
                    settings: {
                        slidesToShow: 7.5,
                        slidesToScroll: 7,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 2000,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 6,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 5.1,
                        slidesToScroll: 5,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 5.1,
                        slidesToScroll: 5.1,
                        infinite: false,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4.1,
                        slidesToScroll: 4.1,
                        infinite: false,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2.1,
                        slidesToScroll: 2,
                        infinite: false,
                        centerMode: false
                    }
                }
            ],
            HORIZONTAL_LG: [
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 4500,
                    settings: {
                        slidesToShow: 5.5,
                        slidesToScroll: 5,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 3500,
                    settings: {
                        slidesToShow: 5.5,
                        slidesToScroll: 5,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 2500,
                    settings: {
                        slidesToShow: 5.5,
                        slidesToScroll: 5,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 2000,
                    settings: {
                        slidesToShow: 3.5,
                        slidesToScroll: 3,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 3.1,
                        slidesToScroll: 3,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2.5,
                        slidesToScroll: 2,
                        infinite: false,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1.5,
                        slidesToScroll: 1,
                        infinite: false,
                        centerMode: false
                    }
                }
            ],
            VERTICAL_LG: [
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 4500,
                    settings: {
                        slidesToShow: 5.5,
                        slidesToScroll: 5,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 3500,
                    settings: {
                        slidesToShow: 5.5,
                        slidesToScroll: 5,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 2500,
                    settings: {
                        slidesToShow: 5.5,
                        slidesToScroll: 5,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 2000,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 5,
                        initialSlide: 1
                    }
                },
                {
                    // Breakpoints to render how much items to show 
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 4.1,
                        slidesToScroll: 4,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3.1,
                        slidesToScroll: 3.1,
                        infinite: false,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2.1,
                        slidesToScroll: 2.1,
                        infinite: false,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1.5,
                        slidesToScroll: 1.5,
                        infinite: false,
                        centerMode: false
                    }
                }
            ]
        },
        LOGIN: {
            G_AUTH_URL: process.env.G_AUTH_URL,
            G_SCOPE: process.env.G_SCOPE,
            G_RES_TYPE: process.env.G_RES_TYPE,
            G_CLIENT_ID: process.env.G_CLIENT_ID,
            G_REDIRECT_URI: process.env.G_REDIRECT_URI,
            FIREBASE_API_KEY: process.env.FIREBASE_API_KEY,
            DEVICE_TOKEN: process.env.DEVICE_TOKEN,
            O_AUTH_URL: process.env.O_AUTH_URL,
            LOGO_URL_50: process.env.LOGO_URL_50,
            LOGO_TEXT_URL: process.env.LOGO_TEXT_URL
        },
        PLAYER_PAGE: {
            SUGGESTION_LIMIT: 15,
            EPISODE_LIMIT: 50,
            JW_PLAYER_SRC: process.env.JW_PLAYER_SRC,
            THEO_LICENSE: process.env.THEO_LICENSE,
            THEO_LIBRARY_LOCATION: process.env.THEO_LIBRARY_LOCATION,
            FB_APP_ID: process.env.FB_APP_ID
        },
        LABEL_ASSETS: {
            pageLimit: 10
        }
    },
    LOGO_TEXT_URL: process.env.LOGO_TEXT_URL,
    HEADER: {
        logo: process.env.LOGO_URL,
        url: process.env.HOST_URL
    },
    FB_APP_ID: process.env.FB_APP_ID,
    FOOTER: {
        pageLimit: 20,
        RIGHTS: process.env.RIGHTS
    },
    G_GTM: process.env.G_GTM,
    G_TAG: process.env.G_TAG,
    AD_SENSE_ID: process.env.AD_SENSE_ID,
    TEST_STREAM_DEFAULT_URL: process.env.TEST_STREAM_DEFAULT_URL,
    TEST_STREAM_AD_VAST_URL: process.env.TEST_STREAM_AD_VAST_URL,
    SHOW_FOOTER_FOR_STATIC_PAGES: false,
    ASSET_TYPES: {
        SERIES: "SERIES",
        EPISODE: "EPISODE",
        LIVE_TV: "LIVE_TV",
        MOVIE: "MOVIE"
    },
    CLIP_TYPE: {
        LIVE: "LIVE",
        VOD: "VOD"
    },
    ASSET_CLASS: {
        AUDIO: "A"
    },
    VIDEO_PLAYER: {
        VIDEOJS: 0,
        THEO: 1,
        BRADMAX: 2,
        JWPLAYER: 3,
        SHAKA: 4,
        YOUTUBE: 5,
        PLYR: 6
    }
};

module.exports = CONFIG;