import React from 'react';
import { Provider } from 'react-redux';
import Router from 'next/router';
import configureStore from '../src/store';
import jwtDecode from 'jwt-decode';
import { getPagesStaticApp, getRefreshToken, getSaltKeyStatic, setCurrentUser, switchMode } from '../src/actions/actions';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "plyr/dist/plyr.css";
import '../styles/home.scss';
import '../styles/account.scss';
import '../styles/index.scss';
import '../styles/individual-page.scss';
import '../styles/login.scss';
import '../styles/globals.scss';
import '../styles/responsive.scss';
// import 'shaka-player/dist/controls.css'; /* Shaka player CSS import */
import NProgress from 'nprogress';
// import ErrorBoundary from '../src/components/error-boundary';

const isServer = typeof window === "undefined";

// Export Token
export const store = configureStore();

let token = null;
let dark_theme = null;
if(!isServer) {
    token = localStorage.getItem("token");
    dark_theme = localStorage.getItem("dark_theme");
}

// Set curreent user based on local storage token
if(token != null) {
    let jsonWebToken = token.substring(7);
    const decodedToken = jwtDecode(jsonWebToken);
    if(Object.keys(decodedToken).length > 5 && decodedToken.type === "C") {
        store.dispatch(setCurrentUser(decodedToken))
    }
}

// Dispatch Dark mode based on local storage
if(dark_theme != null) {
    store.dispatch(switchMode(JSON.parse(dark_theme)))
}

// Loader on page change
NProgress.configure({
    showSpinner: true
})

Router.events.on("routeChangeStart", (url) => {
    NProgress.start();
})

Router.events.on("routeChangeComplete", () => {
    NProgress.done();
})

Router.events.on('routeChangeError', () => NProgress.done());

function MyApp({ Component, pageProps, pages, ...rest }) {   
    const [countryCode, setCountryCode] = React.useState("US");
    React.useEffect(() => {
        var ads = document.getElementsByClassName("adsbygoogle").length;
        for (var i = 0; i < ads; i++) {
            try {
                (adsbygoogle = window.adsbygoogle || []).push({ });
                // enable_page_level_ads: true
            } catch (e) {
                console.log(e)
            }
        }
        document.addEventListener('contextmenu', event => event.preventDefault());
    }, [rest]);

    React.useEffect(() => {
        //Get the button
        if(!isServer) {
            let mybutton = document.getElementById("btn-back-to-top");

            if(mybutton != null) {
                // When the user scrolls down 20px from the top of the document, show the button
                window.onscroll = function () {
                    scrollFunction();
                };
        
                function scrollFunction() {
                    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                        mybutton.style.display = "block";
                    } else {
                        mybutton.style.display = "none";
                    }
                }
    
                if(mybutton != null) {
                    // When the user clicks on the button, scroll to the top of the document
                    mybutton.addEventListener("click", backToTop);
            
                    function backToTop() {
                        document.body.scrollTop = 0;
                        document.documentElement.scrollTop = 0;
                    }
                }
            }
            let country_code = localStorage.getItem("country_code");
            if(country_code != null) {
                setCountryCode(country_code);
            }
        }
        let refresh_token = localStorage.getItem("refresh_token");
        let client_secret = localStorage.getItem("client_secret");
        if(refresh_token != null && client_secret != null) {
            store.dispatch(getRefreshToken({
                client_secret: client_secret,
                refresh_token: refresh_token
            }));
        }
    }, [])

    return (
        // <ErrorBoundary>
            <Provider store={store}>
                <Component {...pageProps} pages={pages} countryCode={countryCode}/>
            </Provider>
    ) 
}

MyApp.getInitialProps = async({ Component, ctx }) => {
    let headerVal = getSaltKeyStatic({ 
        p_id: process.env.P_ID, os_type_id: process.env.OS_TYPE_ID, pkey: process.env.P_KEY 
    });
    let headers = {
        Authorization: headerVal.token,
        oauthtoken: headerVal.access_token,
        pid: process.env.P_ID
    }
    let allPages = await getPagesStaticApp({}, headers);

    // let countryCode = await getCountryCode();

    let pages = allPages.rows.map(page => {
        page.url = page.page_name.split(" ").join("-").toLowerCase();
        page.name = page.page_name;
        page.id = page.page_id;
        return page;
    })

    let pageProps = {};
    if (Component.getInitialProps) {
        pageProps = await Component.getInitialProps(ctx);
    }

    return { pageProps, pages };
}

export default MyApp;
